
import { defineComponent } from 'vue'
import CardDisplay from '@/components/CardDisplay.vue'
import Person from '@/components/Person.vue'

const __default__ = defineComponent({
  name: 'Staff',
  components: {
    CardDisplay
  },
  setup () {
    return {
      Person
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4e5f71f8": (_ctx.$refs.staffCardDisplay.count.y),
  "09c1aa95": (_ctx.$refs.staffCardDisplay.gapSize),
  "352fd191": (_ctx.$refs.staffCardDisplay.maxItemWidth),
  "63e18930": (_ctx.$refs.staffCardDisplay.scale),
  "09c1aa95": (_ctx.$refs.staffCardDisplay.gapSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__