<template>
  <div
    class="card-display"
    tabindex="0"
    @wheel="wheel"
    @keydown="keydown"
    @touchstart="touchstart"
    @touchmove="touchmove"
    @touchend="touchend"
    >
    <transition-group
      name="float"
      tag="div"
      class="card-content"
      :class="{ 'is-touched': isTouched }"
      >
      <div
        class="card"
        v-for="(item, i) in items"
        :key="item.id"
        :style="getStyle(i)">
        <component :is="itemComponent" :data="item" />
      </div>
    </transition-group>
    <div id="pager" v-if="count.x * count.y < items.length">
      <span @click="bigStep(-1)">&lt;</span>
      <span class="separator">&nbsp;</span>
      <span @click="bigStep(1)">&gt;</span>
    </div>
    <!-- <teleport to="#app"> -->
    <!--   <div class="footer"> -->
    <!--     <transition name="float&#45;side"> -->
    <!--       <div id="pager" v&#45;if="count.x * count.y < items.length"> -->
    <!--         <span @click="bigStep(&#45;1)">&#38;lt;</span> -->
    <!--         <span class="separator"> </span> -->
    <!--         <span @click="bigStep(1)">&#38;gt;</span> -->
    <!--       </div> -->
    <!--     </transition> -->
    <!--   </div> -->
    <!-- </teleport> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CardDisplay',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemComponent: Object,
    maxItemWidths: {
      type: Object,
      default: () => ({ small: 240, large: 350 })
    },
    // gapSize: { type: Number, default: getComputedStyle(document.getElementById('app')!).getPropertyValue('--unit-c') },
    maxYCount: { type: Number, default: Infinity }
  },
  setup () {
    return {
      containerSize: ref({ width: 0, height: 0 }),
      descriptionHeight: ref(0),
      gapSize: ref(0),
      count: ref({ x: 0, y: 0 }),
      maxItemWidth: ref(0),
      scale: ref(0),
      prevScrollTimestamp: 0,
      scroll: ref(0),
      isTouched: ref(false),
      touchStartX: ref(0),
      touchCurrentX: ref(0)
    }
  },
  computed: {
    scrollLimit (): number {
      return Math.ceil(this.items.length / this.count.y) - this.count.x
    },
    touchDeltaX (): number {
      return this.touchCurrentX - this.touchStartX
    }
  },
  methods: {
    updateSize () {
      this.containerSize.width = this.$el.clientWidth
      this.containerSize.height = this.$el.clientHeight
      this.gapSize = window.innerHeight * (2.8 / 48.4) // --unit-c
      this.maxItemWidth =
        document.body.clientWidth < 2000
          ? this.maxItemWidths.small
          : this.maxItemWidths.large
      this.descriptionHeight = window.innerHeight * (1.5 / 48.4) // --unit-a

      this.count.x = Math.ceil(
        (this.containerSize.width - this.maxItemWidth) /
        (this.maxItemWidth + this.gapSize)
      ) + 1

      this.scale = (
        (this.containerSize.width - (this.count.x - 1) * this.gapSize) /
        (this.count.x * this.maxItemWidth)
      )

      const itemHeight = this.maxItemWidth * this.scale + this.descriptionHeight
      this.count.y = Math.max(1, Math.min(this.maxYCount,
        Math.floor((this.containerSize.height - itemHeight) / (itemHeight + this.gapSize)) + 1
      ))

      this.scroll = Math.max(0, Math.min(this.scrollLimit, this.scroll))
    },

    getStyle (i: number) {
      return {
        '--x-count': Math.floor(i / this.count.y),
        '--y-count': (i % this.count.y)
      }
    },

    step (direction: number) {
      // this.scroll = (
      //   this.scroll + direction + (this.scrollLimit + 1)
      // ) % (this.scrollLimit + 1)

      this.scroll = Math.max(0, Math.min(this.scrollLimit,
        this.scroll + direction
      ))
    },

    bigStep (direction: number) {
      // if (this.scroll === 0 && direction === -1) {
      //   this.scroll = this.scrollLimit
      // } else if (this.scroll === this.scrollLimit && direction === 1) {
      //   this.scroll = 0
      // } else {
      //   this.scroll = Math.max(0, Math.min(this.scrollLimit,
      //     this.scroll + Math.floor(this.count.x / 2) * direction
      //   ))
      // }

      this.scroll = Math.max(0, Math.min(this.scrollLimit,
        this.scroll + Math.floor(this.count.x / 2) * direction
      ))
    },

    wheel (event: WheelEvent) {
      if (event.timeStamp > this.prevScrollTimestamp + 250) {
        this.bigStep(Math.sign(event.deltaX))
        this.bigStep(Math.sign(event.deltaY))
      }
      this.prevScrollTimestamp = event.timeStamp
      event.preventDefault()
    },

    keydown (event: KeyboardEvent) {
      if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        this.bigStep(
          event.key === 'ArrowLeft' ? -1 : event.key === 'ArrowRight' ? 1 : 0
        )
        event.preventDefault()
      }
    },

    touchstart (event: TouchEvent) {
      this.isTouched = true
      this.touchStartX = event.touches[0].clientX
      this.touchCurrentX = event.touches[0].clientX
    },

    touchmove (event: TouchEvent) {
      this.touchCurrentX = event.touches[0].clientX
      event.preventDefault()
    },

    touchend () {
      this.isTouched = false
      // if (Math.abs(this.touchDeltaX) > 25) {
      //   this.step(-Math.sign(this.touchDeltaX))
      // }

      this.scroll = Math.max(0, Math.min(this.scrollLimit, Math.round(
        this.scroll -
        this.touchDeltaX / (this.maxItemWidth * this.scale + this.gapSize)
      )))

      this.touchStartX = 0
      this.touchCurrentX = 0
    }
  },
  mounted () {
    this.updateSize()
    window.addEventListener('resize', () => {
      this.updateSize()
    })

    this.$el.focus()
    this.$el.addEventListener('blur', () => {
      setTimeout(() => { this.$el.focus() }, 250)
    })
  }
})
</script>

<style scoped>
.card-display {
  outline: none;

  --maxItemWidth: calc(v-bind(maxItemWidth) * 1px);
  --gapSize: calc(v-bind(gapSize) * 1px);
  --scale: v-bind(scale);
  --itemWidth: calc(var(--maxItemWidth) * var(--scale));

  position: relative;
}

.card-content {
  position: relative;

  transform: translateX(calc(
    (v-bind(touchDeltaX) * 1px) -
    (v-bind(scroll) * (var(--gapSize) + var(--itemWidth)))
  ));

  transition: transform 0.5s;
}

.card-content.is-touched {
  transition: none;
}

.card {
  width: var(--itemWidth);
  height: calc(var(--itemWidth) + v-bind(descriptionHeight) * 1px);

  --width: var(--itemWidth);

  position: absolute;
    top: calc(var(--y-count) * (var(--gapSize) + var(--itemWidth)));
    left: calc(var(--x-count) * (var(--gapSize) + var(--itemWidth)));

  transition: all 0.5s;
  /* transition: transform 1s, width 0.25s, height 0.25s, top 0.5s, left 0.5s; */
}

/* .float-item { */
/*   transition: transform 1s ease; */
/* } */

/* .float-enter-active, */
/* .float-leave-active { */
/*   transition: transform 0.75s ease; */
/* } */

.float-enter-from,
.float-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.float-side-enter-from,
.float-side-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* .float-move { */
/*   transition: transform 0.75s ease; */
/* } */

#pager {
  font-size: var(--unit-font-m);
  text-align: right;
  user-select: none;

  transition: all 0.5s;

  position: absolute;
  top: calc(v-bind(count.y) * (var(--gapSize) + var(--itemWidth)));
  right: 10px;
}

#pager span:not(.separator) {
  cursor: pointer;
}

#pager span.separator {
  font-size: calc(var(--unit-font-m) * 1.5);
  margin: 5px;
}
</style>
