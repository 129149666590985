import {
  createRouter,
  createWebHashHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
  RouteRecordName
} from 'vue-router'

import App from '@/App.vue'
import Home from '@/views/Home.vue'
import Projects from '@/views/Projects.vue'
import ProjectsSubMenu from '@/views/ProjectsSubMenu.vue'
import AboutSubMenu from '@/views/AboutSubMenu.vue'
import Office from '@/views/Office.vue'
import Staff from '@/views/Staff.vue'
import PublicationsAndPrizes from '@/views/PublicationsAndPrizes.vue'
import Project from '@/views/Project.vue'

const getLanguageFromHostname = () => {
  const topLevelDomain = location.hostname.split('.').pop()
  const domainToLanguage: { [index: string]: string } = {
    localhost: 'en',
    surface: 'en',
    com: 'hu',
    hu: 'hu'
  }
  return topLevelDomain ? domainToLanguage[topLevelDomain] : 'en'
}

const pages: Array<RouteRecordRaw> = [
  { path: '', name: 'home', components: {}, meta: { content: Home } },
  {
    path: 'projects/:label?',
    name: 'projects',
    components: {},
    meta: { content: Projects, submenu: ProjectsSubMenu }
  },
  {
    path: 'project/:projectId',
    name: 'project',
    components: {},
    meta: { content: Project, submenu: ProjectsSubMenu }
  },
  {
    path: 'about',
    name: 'about',
    components: {},
    meta: { submenu: AboutSubMenu },
    redirect: { name: 'office' },
    children: [
      { path: 'office', name: 'office', components: {}, meta: { content: Office } },
      { path: 'staff', name: 'staff', components: {}, meta: { content: Staff } },
      {
        path: 'publicationsAndPrizes',
        name: 'publicationsAndPrizes',
        components: {},
        meta: { content: PublicationsAndPrizes }
      }
    ]
  }
]

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: `/${getLanguageFromHostname()}` },
  { path: '/:language', component: App, children: pages }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const getNames = (rs: any): RouteRecordName[] => [].concat(...(
  rs.map((r: any) => [
    ...(r.name ? [r.name] : []),
    ...('children' in r ? getNames(r.children) : [])
  ])
))

// const pageNames = pages.map(p => p.name)
const pageNames = getNames(pages)

router.afterEach((to, from) => {
  const getIndex = (route: RouteLocationNormalized) => (
    route.name ? pageNames.indexOf(route.name) : -1
  )

  const toIndex = getIndex(to)
  const fromIndex = getIndex(from)

  to.meta.transition = toIndex > fromIndex ? 'slide-left' : 'slide-right'
})

router.afterEach(to => {
  if (to.name === 'projects' && !to.params.label) {
    to.params.label = 'all'
  }
})

export default router
