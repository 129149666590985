import { createStore } from 'vuex'

// const keyToId = (o: { [index: string]: object }) => (
//   Object.keys(o).map(k => ({ id: k, ...o[k] }))
// )

const separateFirstLine = (text: string) => {
  const [first, ...rest] = text.split('\n')
  return [first, rest.join('\n')]
}

const parseList = (content: string) => content.split('\n---\n').map(p => {
  const [title, description] = separateFirstLine(p)
  return { title, description }
})

const getText = async (source: string) => await (await fetch(source)).text()

const getList = async (source: string) => parseList(await getText(source))

const getLines = async (source: string) => (await getText(source)).split('\n')

const getFirstRest = async (source: string) => {
  const [first, rest] = separateFirstLine(await getText(source))
  return { first, rest }
}

const getNamesAndLinks = async (source: string) => (
  (await getLines(source)).map(line => {
    const [name, link] = line.split('|')
    return { name, link }
  })
)

export default createStore({
  state: {
    data: {
      homeImages: [],
      projects: [],
      office: { en: '', hu: '' },
      contacts: { en: { first: '', rest: '' }, hu: { first: '', rest: '' } },
      staff: [],
      staffOld: [],
      staffOther: { en: [], hu: [] },
      staffConstructors: { en: [], hu: [] },
      publications: { en: [], hu: [] },
      prizes: { en: [], hu: [] }
    }
  },
  mutations: {
    setHomeImages (state, homeImages) {
      state.data.homeImages = homeImages
    },
    setProjects (state, projects) {
      state.data.projects = projects
    },
    setOffice (state, office) {
      state.data.office = office
    },
    setContacts (state, contacts) {
      state.data.contacts = contacts
    },
    setStaff (state, staff) {
      state.data.staff = staff
    },
    setStaffOld (state, staffOld) {
      state.data.staffOld = staffOld
    },
    setStaffOther (state, staffOther) {
      state.data.staffOther = staffOther
    },
    setStaffConstructors (state, staffConstructors) {
      state.data.staffConstructors = staffConstructors
    },
    setPublications (state, publications) {
      state.data.publications = publications
    },
    setPrizes (state, prizes) {
      state.data.prizes = prizes
    }
  },
  actions: {
    async fetchProjectsAndStaff ({ commit }) {
      const data = await (await fetch('/data.php')).json()

      commit('setHomeImages', Object.keys(data.images.home))

      const parseDescription = (description: string) => {
        const parts = description.split('\n---\n')
        return { hu: (window as any).marked.parse(parts[0]), en: (window as any).marked.parse(parts[1]) }
      }

      const projects = await Promise.all(Object.keys(data.projects).map(async id => ({
        id,
        ...(await (await fetch(`/data/projects/${id}/properties.json`)).json()),
        description: parseDescription(
          await (await fetch(`/data/projects/${id}/description.txt`)).text()
        ),
        images: Object.keys(data.projects[id].images)
      })))
      commit('setProjects', projects)

      const staff = await Promise.all(Object.keys(data.staff).map(async id => ({
        id,
        ...(await (await fetch(`/data/staff/${id}/properties.json`)).json())
      })))
      commit('setStaff', staff)

      commit('setStaffOld', await getNamesAndLinks('/data/staff-old.txt'))
      commit('setStaffOther', {
        en: await getNamesAndLinks('/data/staff-other-en.txt'),
        hu: await getNamesAndLinks('/data/staff-other-hu.txt')
      })
      commit('setStaffConstructors', {
        en: await getNamesAndLinks('/data/staff-constructors-en.txt'),
        hu: await getNamesAndLinks('/data/staff-constructors-hu.txt')
      })
    },
    async fetchOfficeAndContacts ({ commit }) {
      commit('setOffice', {
        en: await getText('/data/office-en.txt'),
        hu: await getText('/data/office-hu.txt')
      })
      commit('setContacts', {
        en: await getFirstRest('/data/contacts-en.txt'),
        hu: await getFirstRest('/data/contacts-hu.txt')
      })
    },
    async fetchPublicationsAndPrizes ({ commit }) {
      commit('setPrizes', {
        en: await getList('/data/prizes-en.txt'),
        hu: await getList('/data/prizes-hu.txt')
      })
      commit('setPublications', {
        en: await getList('/data/publications-en.txt'),
        hu: await getList('/data/publications-hu.txt')
      })
    },
    async fetchData ({ dispatch }) {
      dispatch('fetchProjectsAndStaff')
      dispatch('fetchOfficeAndContacts')
      dispatch('fetchPublicationsAndPrizes')
    }
  },
  modules: {
  }
})
