
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'

export default defineComponent({
  name: 'App',
  components: {
    Menu
  },
  computed: {
    navigationMenuItems: function () {
      return ['projects', 'about'].map(name => ({
        text: this.$t(`pages.${name}`),
        target: { name }
      }))
    },
    languageSelectorItems: function () {
      return this.$i18n.availableLocales.map(language => ({
        text: language,
        target: { params: { language } }
      }))
    }
  },
  created () {
    this.$store.dispatch('fetchData')
  }
})
