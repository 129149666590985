
import { defineComponent, ref } from 'vue'

const placeholder = {
  title: { en: '...', hu: '...' },
  location: '...',
  year: 0,
  lead: '...',
  designers: ['...'],
  associates: ['...'],
  consultants: ['...'],
  interior: ['...'],
  prize: { en: '...', hu: '...' },
  status: { en: '...', hu: '...' },
  labels: ['...'],
  images: ['...'],
  description: { en: '', hu: '' }
}

export default defineComponent({
  name: 'Project',
  setup () {
    return {
      imageIndex: ref(0),
      prevScrollTimestamp: ref(0),
      transition: ref('')
    }
  },
  computed: {
    currentProject () {
      const currentProject = this.$store.state.data.projects.filter(
        p => p.id === this.$route.params.projectId
      )[0]
      return currentProject || placeholder
    }
  },
  methods: {
    step (direction: number) {
      this.imageIndex = (
        this.imageIndex + direction + this.currentProject.images.length
      ) % this.currentProject.images.length

      this.transition = `slide-${direction > 0 ? 'left' : 'right'}`
    },
    wheel (event: WheelEvent) {
      if (event.timeStamp > this.prevScrollTimestamp + 250) {
        if (event.deltaX !== 0) { this.step(Math.sign(event.deltaX)) }
        if (event.deltaY !== 0) { this.step(Math.sign(event.deltaY)) }
      }
      this.prevScrollTimestamp = event.timeStamp
      event.preventDefault()
    },
    keydown (event: KeyboardEvent) {
      this.step(
        event.key === 'ArrowLeft' ? -1 : event.key === 'ArrowRight' ? 1 : 0
      )
      event.preventDefault()
    }
  },
  mounted () {
    this.$el.focus()
    this.$el.addEventListener('blur', () => {
      setTimeout(() => { this.$el.focus() }, 250)
    })
  }
})
