
import { defineComponent } from 'vue'

const __default__ = defineComponent({
  name: 'Menu',
  props: {
    items: {
      type: Object
    },
    spacing: {
      type: Number,
      default: 1
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "129dc44f": (_ctx.spacing)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__