<template>
  <div id="header">
    <router-link class="logo-container" :to="`/${$route.params.language}/`">
      <img id="logo" src="/data/images/logo.png" alt="logo" />
    </router-link>
    <nav class="main-menu"><Menu :items="navigationMenuItems" /></nav>
    <Menu class="language-selector" :items="languageSelectorItems" :spacing="0.2" />
    <!-- <br /> -->
    <div class="sub-menu">
      <router-view v-slot="{ route }">
        <transition :name="route.meta.transition">
          <component :is="route.meta.submenu" />
        </transition>
      </router-view>
    </div>
  </div>
  <router-view v-slot="{ route }">
    <transition :name="route.meta.transition">
      <component :is="route.meta.content" />
    </transition>
  </router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Menu from '@/components/Menu.vue'

export default defineComponent({
  name: 'App',
  components: {
    Menu
  },
  computed: {
    navigationMenuItems: function () {
      return ['projects', 'about'].map(name => ({
        text: this.$t(`pages.${name}`),
        target: { name }
      }))
    },
    languageSelectorItems: function () {
      return this.$i18n.availableLocales.map(language => ({
        text: language,
        target: { params: { language } }
      }))
    }
  },
  created () {
    this.$store.dispatch('fetchData')
  }
})
</script>

<style>
#app {
  /* font-family: 'Avenir Medium'; */
  /* font-size: var(--unit-font-l); */
  /* font-family: 'Futura Condensed Medium'; */

  position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

  /* font-size: 16px; */
  --unit: calc(100vh / 48.4);
  --unit-a: calc(var(--unit) * 1.5);
  --unit-b: calc(var(--unit) * 2.3);
  --unit-c: calc(var(--unit) * 3);
  --unit-font-s: calc(clamp(10px, var(--unit) * 0.5 * 1.2, 16px));
  --unit-font-m: calc(clamp(12px, var(--unit) * 0.6 * 1.2, 18px));
  --unit-font-l: calc(clamp(15px, var(--unit) * 0.9, 20px));
  --unit-square: calc(var(--unit) * 11.4);
  --unit-pp: calc(var(--unit) * 20.2);

  max-width: calc(68.2 * var(--unit));
  /* max-width: 1180px; */
  /* margin: 20px auto 200px; */

  margin: auto;
  padding: 0 var(--unit-a) 0;

  color: black;

  display: grid;
  grid-template-rows:
    calc(var(--unit-a) * 2 + var(--unit-b) * 2)
    var(--unit-b)
    calc(var(--unit) * 33.2)
    calc(var(--unit) * 5.3);
  /* grid-template-rows: 150px auto 200px; */
  grid-template-areas:
    "page-header"
    "page-empty"
    "page-content"
    "page-footer";

  overflow: hidden;
}

#app.page-project {
  display: grid;
  grid-template-rows:
    calc(var(--unit-a) * 2 + var(--unit-b) * 2)
    var(--unit-b)
    calc(var(--unit) * 27.1 + var(--unit-a) + var(--unit-b) * 2 + var(--unit-a))
    calc(var(--unit) * 3.8);

  grid-template-areas:
    "page-header"
    "page-empty"
    "page-content"
    "page-footer";
}

.footer {
  grid-area: page-footer;
}
</style>

<style scoped>
#header {
  grid-area: page-header;
  display: grid;
  grid-template-rows:
    calc(var(--unit-a) * 2 + var(--unit-b))
    max(calc(var(--unit) * 1.3), calc(var(--unit-font-l) * 1.25))
    max(calc(var(--unit) * 1), calc(var(--unit-font-m) * 1.25));
  grid-template-columns: 90% 10%;

  grid-template-areas:
    "logo logo"
    "menu lang"
    "subm .   ";
}

.logo-container {
  grid-area: logo;
  place-self: center;
}

#logo {
  display: block;
  /* margin: var(--unit-a) auto var(--unit-a); */
  height: var(--unit-c);
}

.main-menu {
  grid-area: menu;
  font-size: var(--unit-font-l);
  font-family: 'Roboto';
}

#header .language-selector {
  grid-area: lang;
  justify-self: end;
  font-size: var(--unit-font-m);
  font-family: 'Roboto';
  font-weight: 300;
}

.sub-menu {
  grid-area: subm;
  /* align-self: end; */
  position: relative;

  /* margin-top: 12px;
  margin-bottom: 12px; */

  font-size: var(--unit-font-m);
  font-family: 'Roboto';
  font-weight: 300;
}

.sub-menu > * {
  position: absolute;
  bottom: 0px;
}

.content {
  grid-area: page-content;

  min-width: 0;
  min-height: 0;
  /* width: 100%; */
  /* height: 100%; */
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}

.slide-left-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.slide-left-leave-to, .slide-right-enter-from {
  opacity: 0;
  transform: translateX(-100px);
}
</style>
