<template>
  <div class="content" id="publicationsAndPrizes">
    <img src="/data/images/publications-and-prizes.jpg" alt="publications and prizes">
    <div id="publications">
      <span class="title">{{ $t('titles.publications') }}</span>
      <ul>
        <li
          v-for="publication in $store.state.data.publications[$route.params.language]"
          :key="publication.title"
          >
          <span class="title">{{ publication.title }}</span>
          <span class="description">{{ publication.description }}</span>
        </li>
      </ul>
    </div>
    <div id="prizes">
      <span class="title">{{ $t('titles.prizes') }}</span>
      <ul>
        <li
          v-for="prize in $store.state.data.prizes[$route.params.language]"
          :key="prize.title"
          >
          <span class="title">{{ prize.title }}</span>
          <span class="description">{{ prize.description }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PublicationsAndPrizes'
})
</script>

<style scoped>
#publicationsAndPrizes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: calc(var(--unit-a) + var(--unit-b));

  padding-bottom: calc(var(--unit-b) * 2);
}

img {
  grid-area: "img";
  height: 100%;
  width: 100%;

  object-fit:  cover;
}

@media (max-width: 800px) {
  img {
    display: none;
  }

  #publicationsAndPrizes {
    grid-template-columns: repeat(2, 1fr);
  }
}

ul {
  padding: 0;
  list-style: none;
  margin-top: var(--unit-font-s);
}

li {
  margin-bottom: var(--unit-font-s);
}

span.title, span.description {
  display: block;

  font-family: 'Roboto';
  font-size: var(--unit-font-s);

  white-space: pre;
}

span.title {
  font-weight: 800;
}
</style>
