<template>
  <div class="person">
    <component :is="('link' in data) ? 'a' : 'div'"
      :href="data.link" target="_blank">
      <img :src="`/data/staff/${data.id}/profile.jpg`" />
      <span>{{ data.name }}</span>
      <span>{{ $t(`roles.${data.role}`) }}</span>
    </component>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Person',
  props: ['data']
})
</script>

<style scoped>
.person {
  width: 100%;
  height: 100%;

  user-select: none;
}

.person a {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
  height: var(--width);
  object-fit: cover;

  /* filter: grayscale(100%);
  transition: filter 1s; */
}

/* img:hover {
  filter: grayscale(0%);
} */

span {
  display: block;

  font-family: 'Roboto';
  font-size: var(--unit-font-s);

  /* margin-top: -3px; */
}
</style>
