
import { defineComponent } from 'vue'
import CardDisplay from '@/components/CardDisplay.vue'
import Building from '@/components/Building.vue'

export default defineComponent({
  name: 'Projects',
  components: {
    CardDisplay
  },
  setup () {
    return {
      Building
    }
  },
  methods: {
    getFilteredProjects () {
      const predicate =
        this.$route.params.label === 'all' ? () => true
          : (project: any) => project.labels.includes(this.$route.params.label)

      return this.$store.state.data.projects.sort((p1, p2) => p2.year - p1.year).filter(predicate)
    }
  },
  created () {
    setInterval(() => { this.$forceUpdate() }, 1000) // TODO: Test without this
  },
  watch: {
    $route (to, from) {
      if (to.params.label !== from.params.label) {
        (this.$refs.buildingCardDisplay as any).scroll = 0
      }
    }
  }
})
