
import { defineComponent, ref } from 'vue'

const __default__ = defineComponent({
  name: 'CardDisplay',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemComponent: Object,
    maxItemWidths: {
      type: Object,
      default: () => ({ small: 240, large: 350 })
    },
    // gapSize: { type: Number, default: getComputedStyle(document.getElementById('app')!).getPropertyValue('--unit-c') },
    maxYCount: { type: Number, default: Infinity }
  },
  setup () {
    return {
      containerSize: ref({ width: 0, height: 0 }),
      descriptionHeight: ref(0),
      gapSize: ref(0),
      count: ref({ x: 0, y: 0 }),
      maxItemWidth: ref(0),
      scale: ref(0),
      prevScrollTimestamp: 0,
      scroll: ref(0),
      isTouched: ref(false),
      touchStartX: ref(0),
      touchCurrentX: ref(0)
    }
  },
  computed: {
    scrollLimit (): number {
      return Math.ceil(this.items.length / this.count.y) - this.count.x
    },
    touchDeltaX (): number {
      return this.touchCurrentX - this.touchStartX
    }
  },
  methods: {
    updateSize () {
      this.containerSize.width = this.$el.clientWidth
      this.containerSize.height = this.$el.clientHeight
      this.gapSize = window.innerHeight * (2.8 / 48.4) // --unit-c
      this.maxItemWidth =
        document.body.clientWidth < 2000
          ? this.maxItemWidths.small
          : this.maxItemWidths.large
      this.descriptionHeight = window.innerHeight * (1.5 / 48.4) // --unit-a

      this.count.x = Math.ceil(
        (this.containerSize.width - this.maxItemWidth) /
        (this.maxItemWidth + this.gapSize)
      ) + 1

      this.scale = (
        (this.containerSize.width - (this.count.x - 1) * this.gapSize) /
        (this.count.x * this.maxItemWidth)
      )

      const itemHeight = this.maxItemWidth * this.scale + this.descriptionHeight
      this.count.y = Math.max(1, Math.min(this.maxYCount,
        Math.floor((this.containerSize.height - itemHeight) / (itemHeight + this.gapSize)) + 1
      ))

      this.scroll = Math.max(0, Math.min(this.scrollLimit, this.scroll))
    },

    getStyle (i: number) {
      return {
        '--x-count': Math.floor(i / this.count.y),
        '--y-count': (i % this.count.y)
      }
    },

    step (direction: number) {
      // this.scroll = (
      //   this.scroll + direction + (this.scrollLimit + 1)
      // ) % (this.scrollLimit + 1)

      this.scroll = Math.max(0, Math.min(this.scrollLimit,
        this.scroll + direction
      ))
    },

    bigStep (direction: number) {
      // if (this.scroll === 0 && direction === -1) {
      //   this.scroll = this.scrollLimit
      // } else if (this.scroll === this.scrollLimit && direction === 1) {
      //   this.scroll = 0
      // } else {
      //   this.scroll = Math.max(0, Math.min(this.scrollLimit,
      //     this.scroll + Math.floor(this.count.x / 2) * direction
      //   ))
      // }

      this.scroll = Math.max(0, Math.min(this.scrollLimit,
        this.scroll + Math.floor(this.count.x / 2) * direction
      ))
    },

    wheel (event: WheelEvent) {
      if (event.timeStamp > this.prevScrollTimestamp + 250) {
        this.bigStep(Math.sign(event.deltaX))
        this.bigStep(Math.sign(event.deltaY))
      }
      this.prevScrollTimestamp = event.timeStamp
      event.preventDefault()
    },

    keydown (event: KeyboardEvent) {
      if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        this.bigStep(
          event.key === 'ArrowLeft' ? -1 : event.key === 'ArrowRight' ? 1 : 0
        )
        event.preventDefault()
      }
    },

    touchstart (event: TouchEvent) {
      this.isTouched = true
      this.touchStartX = event.touches[0].clientX
      this.touchCurrentX = event.touches[0].clientX
    },

    touchmove (event: TouchEvent) {
      this.touchCurrentX = event.touches[0].clientX
      event.preventDefault()
    },

    touchend () {
      this.isTouched = false
      // if (Math.abs(this.touchDeltaX) > 25) {
      //   this.step(-Math.sign(this.touchDeltaX))
      // }

      this.scroll = Math.max(0, Math.min(this.scrollLimit, Math.round(
        this.scroll -
        this.touchDeltaX / (this.maxItemWidth * this.scale + this.gapSize)
      )))

      this.touchStartX = 0
      this.touchCurrentX = 0
    }
  },
  mounted () {
    this.updateSize()
    window.addEventListener('resize', () => {
      this.updateSize()
    })

    this.$el.focus()
    this.$el.addEventListener('blur', () => {
      setTimeout(() => { this.$el.focus() }, 250)
    })
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "deddffe6": (_ctx.maxItemWidth),
  "a27742ce": (_ctx.gapSize),
  "a511bf28": (_ctx.scale),
  "bffadd3e": (_ctx.touchDeltaX),
  "0174b3eb": (_ctx.scroll),
  "6610ef36": (_ctx.descriptionHeight),
  "193bb408": (_ctx.count.y)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__