<template>
  <router-link :to="{ name: 'project', params: { projectId: data.id } }">
    <div class="building">
      <!-- <img :src="`/data/projects/${data.id}/images/${data.images[0]}`" /> -->
      <img :src="`/data/projects/${data.id}/thumbnail.jpg`" />
      <span>{{ data.title[$route.params.language] }}</span>
      <!-- <span>{{ data.location }}, {{ data.year }}</span> -->
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Building',
  props: ['data']
})
</script>

<style scoped>
.building {
  width: 100%;
  height: 100%;

  user-select: none;
}

a {
  width: 100%;
  height: 100%;

  color: black;
  text-decoration: none;
}

img {
  width: 100%;
  height: var(--width);

  object-fit: cover;
}

span {
  display: block;

  font-family: 'Roboto';
  font-size: var(--unit-font-s);

  text-align: left;
  white-space: pre;

  /* margin-top: -3px; */
}
</style>
