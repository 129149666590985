
import Menu from '@/components/Menu.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProjectsSubMenu',
  components: {
    Menu
  },
  computed: {
    filterMenuItems: function () {
      return [
        'all',
        'residential-building',
        'public-building',
        'interior-design',
        'design-competition'
      ].map(label => ({
        text: this.$t(`label.${label}`),
        target: { name: 'projects', params: { label } }
      }))
    }
  }
})
