<template>
  <ul class="menu">
    <li class="item" v-for="item in items" v-bind:key="item.text">
      <router-link :to="item.target" >{{ item.text }}</router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Menu',
  props: {
    items: {
      type: Object
    },
    spacing: {
      type: Number,
      default: 1
    }
  }
})
</script>

<style scoped>
.menu {
  display: flex;

  margin: 0;
  padding: 0;

  user-select: none;
}

.menu > * + * {
  margin-left: calc(v-bind(spacing) * var(--unit-a));
}

.item {
  display: inline-block;
}

a {
  color: black;
  text-decoration: none;
  position: relative;
}

/* a.router-link-active {
  text-decoration: underline;
} */

.main-menu a.router-link-active {
  font-weight: 700;
}

.sub-menu a.router-link-active {
  font-weight: 500;
}

.language-selector a.router-link-active {
  font-weight: 500;
}

/* a.router-link-active {
  text-decoration: underline;
} */

/* a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: calc(var(--unit) * 0.05);
  width: 0px;
  height: calc(var(--unit) * 0.05);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  background-color: black;
} */

/* a:hover:after, a.router-link-active::after {
  width: 100%;
  opacity: 1;
} */
</style>
