<template>
  <div class="content" id="home">
    <transition-group id="splashContainer" tag="div" name="splash">
      <img
        v-if="currentIndex >= 0"
        :key="`img-${currentIndex}`"
        :src="`/data/images/home/${images[currentIndex]}`"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Home',
  setup () {
    return {
      currentIndex: ref(-1)
    }
  },
  computed: {
    images () {
      return this.$store.state.data.homeImages
    }
  },
  methods: {
    init () {
      if (this.images.length > 0) {
        this.show()
      } else {
        setTimeout(this.init, 250)
      }
    },
    show () {
      const nextIndex = (this.currentIndex + 1) % this.images.length

      const image = new Image()
      image.addEventListener('load', () => {
        this.currentIndex = nextIndex
        setTimeout(this.show, 5000)
      })
      image.src = `/data/images/home/${this.images[nextIndex]}`
    }
  },
  mounted () {
    this.init()
  }
})
</script>

<style scoped>
#splashContainer {
  position: relative;
}

.splash-enter-active,
.splash-leave-active {
  transition: all 1s ease;
}

.splash-enter-from,
.splash-leave-to {
  opacity: 0;
}

img {
  width: 100%;
  height: calc(var(--unit) * 33.2);

  position: absolute;

  object-fit: cover;
}
</style>
