<template>
  <div class="content" id="project" tabindex="0" @keydown="keydown">
    <transition-group
      :name="transition" mode="out-in" tag="div"
      class="sub-content" id="pictures"
      @wheel="wheel"
      >
      <a
        :key="`img-${imageIndex}`"
        :href="`/data/projects/${currentProject.id}/images/${currentProject.images[imageIndex]}`"
      >
        <img :src="`/data/projects/${currentProject.id}/previews/${currentProject.images[imageIndex]}`">
      </a>
    </transition-group>
    <div id="counter">
      <span @click="step(-1)">&lt;</span>
      {{ imageIndex + 1 }}/{{ currentProject.images.length }}
      <span @click="step(1)">&gt;</span>
    </div>
    <div id="details">
      <h1>{{ currentProject.title[$route.params.language] }}</h1>
      <p v-if="currentProject.location.length > 0">
        {{ $t('properties.location') }}:
        {{ currentProject.location }}
      </p>
      <p v-if="currentProject.year > 0">
        {{ $t('properties.year') }}:
        {{ currentProject.year }}
      </p>
      <p v-if="currentProject.lead.length > 0">
        {{ $t('properties.lead') }}:
        {{ currentProject.lead }}
      </p>
      <p v-if="currentProject.designers.length > 0">
        {{ $t('properties.designers') }}:
        {{ currentProject.designers.join(', ') }}
      </p>
      <p v-if="currentProject.associates.length > 0">
        {{ $t('properties.associates') }}:
        {{ currentProject.associates.join(', ') }}
      </p>
      <p v-if="currentProject.consultants.length > 0">
        {{ $t('properties.consultants') }}:
        {{ currentProject.consultants.join(', ') }}
      </p>
      <p v-if="currentProject.interior.length > 0">
        {{ $t('properties.interior') }}:
        {{ currentProject.interior.join(', ') }}
      </p>
      <p v-if="currentProject.prize[$route.params.language].length > 0">
        {{ $t('properties.prize') }}:
        {{ currentProject.prize[$route.params.language] }}
      </p>
      <p v-if="currentProject.status[$route.params.language].length > 0">
        {{ currentProject.status[$route.params.language] }}
      </p>
    </div>
    <div id="longText" v-html="currentProject.description[$route.params.language]"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

const placeholder = {
  title: { en: '...', hu: '...' },
  location: '...',
  year: 0,
  lead: '...',
  designers: ['...'],
  associates: ['...'],
  consultants: ['...'],
  interior: ['...'],
  prize: { en: '...', hu: '...' },
  status: { en: '...', hu: '...' },
  labels: ['...'],
  images: ['...'],
  description: { en: '', hu: '' }
}

export default defineComponent({
  name: 'Project',
  setup () {
    return {
      imageIndex: ref(0),
      prevScrollTimestamp: ref(0),
      transition: ref('')
    }
  },
  computed: {
    currentProject () {
      const currentProject = this.$store.state.data.projects.filter(
        p => p.id === this.$route.params.projectId
      )[0]
      return currentProject || placeholder
    }
  },
  methods: {
    step (direction: number) {
      this.imageIndex = (
        this.imageIndex + direction + this.currentProject.images.length
      ) % this.currentProject.images.length

      this.transition = `slide-${direction > 0 ? 'left' : 'right'}`
    },
    wheel (event: WheelEvent) {
      if (event.timeStamp > this.prevScrollTimestamp + 250) {
        if (event.deltaX !== 0) { this.step(Math.sign(event.deltaX)) }
        if (event.deltaY !== 0) { this.step(Math.sign(event.deltaY)) }
      }
      this.prevScrollTimestamp = event.timeStamp
      event.preventDefault()
    },
    keydown (event: KeyboardEvent) {
      this.step(
        event.key === 'ArrowLeft' ? -1 : event.key === 'ArrowRight' ? 1 : 0
      )
      event.preventDefault()
    }
  },
  mounted () {
    this.$el.focus()
    this.$el.addEventListener('blur', () => {
      setTimeout(() => { this.$el.focus() }, 250)
    })
  }
})
</script>

<style scoped>
#project {
  outline: none;
  grid-area: page-content;

  display: grid;

  grid-template-rows:
    calc(var(--unit) * 27.1)
    var(--unit-a)
    calc(var(--unit-b) * 2 + var(--unit-a));

  grid-template-columns: 30% 70%;

  grid-template-areas:
    "img img"
    "lar lar"
    "det tex";

    /* overflow: hidden; */
}

#pictures {
  grid-area: img;
  place-self: center;

  width: 100%;
  height: 100%;
  position: relative;
}

#pictures a {
  display: block;

  width: 100%;
  height: 100%;

  position: absolute;

  cursor: zoom-in;
}

#pictures a img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

#counter {
  grid-area: lar;
  justify-self: end;
  /* text-align: right; */
  font-family: "Roboto";
  font-size: var(--unit-font-m);
  /* margin-bottom: 10px; */

  user-select: none;
}

#counter span {
  cursor: pointer;
}

#details {
  grid-area: det;
  /* flex-shrink: 0;
  margin-right: 40px; */
}

#details h1 {
  font-family: 'Roboto';
  font-size: var(--unit-font-l);

  margin-top: 0;
  margin-bottom: 3px;
}

#details p {
  font-family: 'Roboto';
  font-size: var(--unit-font-m);
  margin: 0;
}

#longText {
  text-align: justify;
  margin-left: calc(var(--unit-a) + var(--unit-b));

  font-family: 'Roboto';
  font-size: var(--unit-font-s);
}

/* #longText p:first-child {
  font-weight: bold;
} */

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}

.slide-left-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

.slide-left-leave-to, .slide-right-enter-from {
  opacity: 0;
  transform: translateX(-50%);
}
</style>

<style>
#longText * { margin: 0; padding: 0; }
#longText a { color: black; }
#longText ul, #longText li { padding-left: 10px; }
</style>
