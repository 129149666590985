import { createI18n } from 'vue-i18n'

export default createI18n({
  messages: {
    en: {
      language: 'English',
      named: 'Hello, {name}!',
      linked: '@:message.named How are you?',
      pages: {
        home: 'home',
        projects: 'projects',
        staff: 'staff',
        contacts: 'contacts',
        about: 'about',
        office: 'office',
        publicationsAndPrizes: 'publications and prizes',
        project: 'project'
      },
      staff: {
        old: 'Previous colleagues',
        other: 'Partners',
        constructors: 'Constructors'
      },
      label: {
        all: 'all',
        'interior-design': 'interior design',
        'residential-building': 'residential building',
        'public-building': 'public building',
        'design-competition': 'design competition'
      },
      status: {
        unknown: '',
        planned: 'planned',
        built: 'built'
      },
      titles: {
        publications: 'Publications',
        prizes: 'Prizes'
      },
      roles: {
        architect: 'architect',
        'architect-intern': 'architect intern',
        'interior-designer': 'interior designer',
        'lead-designer': 'lead designer'
        // 'graduate-architect': 'graduate architect'
      },
      properties: {
        location: 'location',
        year: 'year',
        lead: 'lead designer',
        designers: 'designer',
        associates: 'associate',
        consultants: 'consultant',
        interior: 'interior design',
        prize: 'prize'
      }
    },
    hu: {
      language: 'Magyar',
      named: 'Szia, {name}!',
      linked: '@:message.named Hogy vagy?',
      pages: {
        home: 'kezdőlap',
        projects: 'munkák',
        staff: 'munkatársak',
        contacts: 'kapcsolat',
        about: 'rólunk',
        office: 'iroda',
        publicationsAndPrizes: 'publikációk és díjak',
        project: 'projekt'
      },
      staff: {
        old: 'Korábbi munkatársaink',
        other: 'Partnerek',
        constructors: 'Kivitelezők'
      },
      label: {
        all: 'mind',
        'interior-design': 'belsőépítészet',
        'residential-building': 'lakóépület',
        'public-building': 'középület',
        'design-competition': 'pályázat'
      },
      status: {
        unknown: '',
        planned: 'tervezett',
        built: 'megépült'
      },
      titles: {
        publications: 'Publikációk',
        prizes: 'Díjak'
      },
      roles: {
        architect: 'építész',
        'architect-intern': 'építész gyakornok',
        'interior-designer': 'belsőépítész',
        'lead-designer': 'vezető tervező'
        // 'graduate-architect': 'okleveles építész'
      },
      properties: {
        location: 'helyszín',
        year: 'tervezés éve',
        lead: 'vezető tervező',
        designers: 'tervező',
        associates: 'munkatárs',
        consultants: 'konzulens',
        interior: 'belsőépítészet',
        prize: 'díjazás'
      }
    }
  }
})
