<template>
  <div class="content" id="contacts">
    <div id="text">
      <div id="about-text">
        <span>{{ $store.state.data.office[$route.params.language] }}</span>
      </div>
      <div id="infobox">
        <p>{{ $store.state.data.contacts[$route.params.language].first }}</p>
        <p>{{ $store.state.data.contacts[$route.params.language].rest }}</p>
      </div>
    </div>
    <img src="/data/images/office.jpg" alt="office">
  </div>
</template>

<style scoped>
#contacts {
  display: flex;
  flex-direction: column;
}

#text {
  display: flex;
  margin-bottom: var(--unit-a);
}

/* #text > div {
  display: inline-block;
} */

/* #about-text h1, p {
  display: inline-block;
} */

/* #about-text {
  max-width: 75%;
} */

#about-text {
  text-align: justify;
  font-size: var(--unit-font-s);
  font-family: 'Roboto';
}

/* #about-text span:first-child { */
/*   float: right; */
/*   font-family: 'Avenir Medium'; */
/*   font-weight: 800; */
/* } */

#infobox {
  /* float: right; */
  flex-shrink: 0;
  margin-left: calc(var(--unit-a) + var(--unit-b));
  width: calc(var(--unit) * 16.4);

  white-space: pre;
}

#infobox p:first-child {
  font-family: 'Roboto';
  font-size: var(--unit-font-l);
  font-weight: 600;
}

#infobox p {
  font-family: 'Roboto';
  font-size: var(--unit-font-m);
  margin: -1px 0 -1px 0;
}

img {
  height: calc(var(--unit) * 27.1);
  width: 100%;

  min-height: 0;

  object-fit:  cover;

  /* padding-top: 40px; */
}
</style>
