
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Home',
  setup () {
    return {
      currentIndex: ref(-1)
    }
  },
  computed: {
    images () {
      return this.$store.state.data.homeImages
    }
  },
  methods: {
    init () {
      if (this.images.length > 0) {
        this.show()
      } else {
        setTimeout(this.init, 250)
      }
    },
    show () {
      const nextIndex = (this.currentIndex + 1) % this.images.length

      const image = new Image()
      image.addEventListener('load', () => {
        this.currentIndex = nextIndex
        setTimeout(this.show, 5000)
      })
      image.src = `/data/images/home/${this.images[nextIndex]}`
    }
  },
  mounted () {
    this.init()
  }
})
