<template>
  <div class="content" id="staff">
    <!-- <div id="staffCurrent"> -->
    <CardDisplay
      ref="staffCardDisplay"
      :items="$store.state.data.staff"
      :itemComponent="Person"
      :maxYCount="2"
      />
    <!-- </div> -->
    <div id="extraContainer">
      <div class="staffExtra" id="staffOld">
        <h4>{{ $t('staff.old') }}:</h4>
        <div>
          <component
            class="person"
            v-for="person in $store.state.data.staffOld"
            :key="person"
            :is="('link' in person) ? 'a' : 'span'"
            :href="person.link" target="_blank">
            {{ person.name }}
          </component>
        </div>
      </div>
      <div class="staffExtra" id="staffOther">
        <h4>{{ $t('staff.other') }}:</h4>
        <div>
          <component
            class="person"
            v-for="person in $store.state.data.staffOther[$route.params.language]"
            :key="person"
            :is="('link' in person) ? 'a' : 'span'"
            :href="person.link" target="_blank">
            {{ person.name }}
          </component>
        </div>
      </div>
      <div class="staffExtra" id="staffConstructors">
        <h4>{{ $t('staff.constructors') }}:</h4>
        <div>
          <component
            class="person"
            v-for="person in $store.state.data.staffConstructors[$route.params.language]"
            :key="person"
            :is="('link' in person) ? 'a' : 'span'"
            :href="person.link" target="_blank">
            {{ person.name }}
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CardDisplay from '@/components/CardDisplay.vue'
import Person from '@/components/Person.vue'

export default defineComponent({
  name: 'Staff',
  components: {
    CardDisplay
  },
  setup () {
    return {
      Person
    }
  }
})
</script>

<style scoped>
#staff {
  width: 100%;
  height: 100%;

  position: relative;
}

#staff .card-display {
  width: 100%;
  height: 100%;
}

#extraContainer {
  position: absolute;
  top: calc(
    /* Number of rows displayed. */
    v-bind($refs.staffCardDisplay.count.y) *

    /* Size of one row (gap + scaled image) */
    ((v-bind($refs.staffCardDisplay.gapSize) +
    v-bind($refs.staffCardDisplay.maxItemWidth) *
    v-bind($refs.staffCardDisplay.scale)) * 1px)

    /* Remove gap after the last row */
    - v-bind($refs.staffCardDisplay.gapSize) * 1px

    /* Padding to avoid overlap with pager widget */
    + var(--unit-b)

    /* Add 2x the font size*/
    + var(--unit-font-s) * 2
  );
}

.staffExtra {
  display: flex;
  overflow: auto;
  font-family: 'Roboto';
  font-size: var(--unit-font-s);

  margin-bottom: 10px;
}

.staffExtra h4 {
  margin: 0px 5px 0px 0px;
  font-weight: normal;
}

.staffExtra .person {
  margin: 0px 5px 0px 0px;
  text-decoration: none;

  /* font-size: var(--unit-font-s); */
}

a.person {
  color: black;
}
</style>
