<template>
  <Menu :items="aboutMenuItems" />
</template>

<script lang="ts">
import Menu from '@/components/Menu.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AboutSubMenu',
  components: {
    Menu
  },
  computed: {
    aboutMenuItems: function () {
      return ['office', 'staff', 'publicationsAndPrizes'].map(name => ({
        text: this.$t(`pages.${name}`),
        target: { name }
      }))
    }
  }
})
</script>
