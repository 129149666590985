
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LanguageAndTitleWatcher',
  watch: {
    $route (to, from) {
      this.$i18n.locale = to.params.language

      document.title = `bataridesign - ${this.$t(`pages.${to.name}`)}`

      const app = document.getElementById('app')!
      app.classList.remove(`page-${from.name}`)
      app.classList.add(`page-${to.name}`)
    }
  }
})
